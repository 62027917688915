import axios from 'axios'
import { userService } from './user.service'
import Swal from 'sweetalert2'
import i18n from "../i18n"

export const methods = {
    post: 'POST',
    get: 'GET',
    put: 'PUT',
    patch: 'PATCH',
    delete: 'DELETE'
}

// API configuration
const API_URL = process.env.VUE_APP_BACKEND_URL
const TOKEN_API = process.env.VUE_APP_TOKEN_API

// Axios instance
const apiClient = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${TOKEN_API}`,
    },
    withCredentials: true
})

// Response interceptor
apiClient.interceptors.response.use(
    response => response.data,
    error => {
        if (error.response?.status === 401) {
            if (error.response?.data?.redirect_url) {
                window.location.href = error.response.data.redirect_url
                return
            }
            window.location.href = '/logout'
            userService.logout()
        }
        return Promise.reject(error.response?.data?.message || error.message)
    }
)

export function makeRequestOptions(method, path, body, { formData = false, cache = false, simpleFilter = false } = {}) {
    const config = {
        method,
        url: path,
        headers: {
            'Authorization': `Bearer ${TOKEN_API}`,
        },
    }

    console.log('makeRequestOptions', method, path, body)

    if (method === 'GET' && body) {
        const params = new URLSearchParams()

        if (!simpleFilter) {
            // Pagination params
            if (body.first !== undefined && body.rows) {
                params.append('page', Math.floor(body.first / body.rows) + 1)
                params.append('limit', body.rows)
            }

            // Sorting params
            if (body.sortField) {
                params.append('sort_by', body.sortField)
                params.append('sort_order', body.sortOrder === 1 ? 'asc' : 'desc')
            }

            // Global search
            if (body.filters?.global?.value) {
                params.append('search', body.filters.global.value)
            }

            // Specific filters
            if (body.filters) {
                Object.entries(body.filters).forEach(([key, filter]) => {
                    if (key !== 'global' && filter.constraints?.[0]?.value !== null) {
                        let { value, matchMode } = filter.constraints[0]
                        if (typeof value === 'boolean') {
                            value = value ? 'true' : 'false'
                        }
                        params.append(`filter[${key}]`, JSON.stringify({ value, matchMode }))
                    }
                })
            }
        } else {
            // Simple filter mode
            Object.entries(body).forEach(([key, value]) => {
                if (value !== null) {
                    params.append(key, value)
                }
            })
        }

        config.params = params
    } else if (!formData && body) {
        config.data = body
    }

    if (formData) {
        delete config.headers['Content-Type']
        config.data = body
    }

    const request = apiClient(config)

    if (cache && method === 'GET') {
        // const cacheKey = `${path}?${new URLSearchParams(config.params).toString()}`
        // request.then(data => {
        //     localStorage.setItem(cacheKey, JSON.stringify({ data, timestamp: Date.now() }))
        // })
    }

    return request
}

export function makeRequestMultiPart(method, path, formData) {
    return apiClient({
        method,
        url: path,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const alertDelete = (callback) => {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-success  ms-2"
        },
        buttonsStyling: false
    })

    return swalWithBootstrapButtons.fire({
        title: i18n.global.t('t-deleteConfirmation'),
        text: i18n.global.t('t-deleteWarning'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: i18n.global.t('t-confirmDelete'),
        cancelButtonText: i18n.global.t('t-cancelDelete')
    }).then((result) => {
        if (result.value) {
            return callback()
                .then(() => {
                    swalWithBootstrapButtons.fire(
                        i18n.global.t('t-deleted'),
                        i18n.global.t('t-deleteSuccess'),
                        "success"
                    )
                })
                .catch((error) => {
                    swalWithBootstrapButtons.fire(
                        i18n.global.t('t-error'),
                        error,
                        "error"
                    )
                    throw error
                })
        } else if (
            result.dismiss === Swal.DismissReason.cancel
        ) {
            swalWithBootstrapButtons.fire(
                i18n.global.t('t-cancelled'),
                i18n.global.t('t-cancelMessage'),
                "error"
            )
        }
    })
}

export function transformKBorMBorGB(size) {
    if (size === 0) {
        return '0 KB'
    } else if (size < 1024) {
        return `${(size).toFixed(2)} KB`
    } else if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2)} MB`
    } else {
        return `${(size / (1024 * 1024)).toFixed(2)} GB`
    }
}

export function getCurrentDate() {
    // Obtém a data atual no formato YYYY-MM-DD
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // Adiciona zero à esquerda se necessário
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

export const formatDate = (dateValue) => {
    if (!dateValue) return ''

    try {
        const date = new Date(dateValue)
        if (isNaN(date.getTime())) return ''
        return date.toLocaleDateString('pt-BR')
    } catch (error) {
        return dateValue
    }
}

export const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-PY', { style: 'currency', currency: 'PYG' }).format(value)
}