export const menuItems = [
  {
    session: "dashboards",
    icon: "ri-dashboard-2-line",
    name: "t-dashboards",
    childrens: [
      {
        path: "/dashboard/choiced",
        name: "dashboard-choiced",
        meta: {
          title: "Custom",
          authRequired: true,
        },
        label: "t-custom",
        visible: false,
        component: () => import("../views/dashboard/general/choiced"),
        permission: "dashboard_general_view"
      },
      {
        path: "/dashboard/choice",
        name: "dashboard-choice",
        meta: {
          title: "Custom",
          authRequired: true,
        },
        label: "t-custom",
        component: () => import("../views/dashboard/general/choice"),
        permission: "dashboard_customize_view"
      },
      {
        path: "/general",
        name: "default",
        meta: {
          title: "Analytics",
          authRequired: true,
        },
        label: "t-analytics",
        component: () => import("../views/dashboard/analysis/index"),
        permission: "dashboard_analytics_view"
      },
      {
        path: "/forecasting",
        name: "forecasting",
        meta: {
          title: "Forecasting",
          authRequired: true,
        },
        label: "t-forecasting",
        component: () => import("../views/dashboard/forecasting/index"),
        permission: "dashboard_forecasting_view"
      },
      {
        path: "/inventory",
        name: "inventory",
        meta: {
          title: "Inventory",
          authRequired: true
        },
        label: "t-inventory",
        component: () => import("../views/dashboard/inventory/index"),
        permission: "dashboard_inventory_view"
      },
      {
        path: "/pricing",
        name: "Pricing",
        meta: {
          title: "Pricing",
          authRequired: true,
        },
        label: "t-price-analysis",
        component: () => import("../views/dashboard/pricing/index"),
        permission: "dashboard_pricing_view"
      },
      {
        path: "/product-dashboard",
        name: "dashboard-product",
        meta: {
          title: "Products",
          authRequired: true
        },
        label: "t-products",
        component: () => import("../views/dashboard/analytics_products/index"),
        permission: "dashboard_products_view"
      }
    ]
  },
  {
    session: "operation",
    icon: "ri-tv-2-line",
    name: "t-operation",
    childrens: [
      {
        path: "/operation/sale-indication",
        name: "operation-sale-indication",
        meta: {
          title: "Sale Indication",
          authRequired: true
        },
        label: "t-sale-indication",
        component: () => import("../views/operation/SaleIndication.vue"),
        permission: "operation_saleindication_view"
      },
      {
        path: "/operation/prescriptions",
        name: "operation-prescriptions",
        meta: {
          title: "Prescriptions",
          authRequired: true
        },
        label: "t-prescriptions",
        component: () => import("../views/operation/Prescriptions.vue"),
        permission: "operation_prescriptions_view"
      },
      {
        path: "/operation/PDV",
        name: "operation-pdv",
        meta: {
          title: "PDV",
          authRequired: true
        },
        label: "t-pdv",
        component: () => import("../views/operation/PDV.vue"),
        permission: "operation_pdv_view"
      }
    ]
  },
  {
    session: "marketing",
    icon: "ri-bill-line",
    name: "t-marketing",
    childrens: [
      {
        path: "/marketing/prescriptions",
        name: "marketing-prescriptions",
        meta: {
          title: "Prescriptions",
          authRequired: true
        },
        label: "t-prescriptions",
        component: () => import("../views/marketing/prescriptions.vue"),
        permission: "marketing_prescriptions_view"
      },
      {
        path: "/marketing/promotions",
        name: "marketing-promotions",
        meta: {
          title: "Promotions",
          authRequired: true
        },
        label: "t-promotions",
        component: () => import("../views/marketing/promotions.vue"),
        permission: "marketing_promotions_view"
      },
      {
        path: "/marketing/coupons",
        name: "marketing-coupons",
        meta: {
          title: "Coupons",
          authRequired: true
        },
        label: "t-coupons",
        component: () => import("../views/marketing/coupons.vue"),
        permission: "marketing_coupons_view"
      }
    ]
  },
  {
    session: "customers",
    icon: "ri-user-3-line",
    name: "t-customers",
    childrens: [
      {
        path: "/customers/customers",
        name: "customers-customers",
        meta: {
          title: "Customers",
          authRequired: true
        },
        label: "t-customers",
        component: () => import("../views/customers/Customers"),
        permission: "customers_customers_view"
      },
      {
        path: "/customers/prescriptions",
        name: "customers-prescriptions",
        meta: {
          title: "Prescriptions",
          authRequired: true
        },
        label: "t-prescriptions",
        component: () => import("../views/customers/Prescriptions.vue"),
        permission: "customers_prescriptions_view"
      },
      {
        path: "/customers/customers-analisys",
        name: "customer-analisys",
        meta: {
          title: "Customer Analysis",
          authRequired: true
        },
        label: "t-customers-analisys",
        component: () => import("../views/customers/CustomerAnalisys"),
        permission: "customers_analysis_view"
      },
      {
        path: "/customers/customers-sales-history",
        name: "customer-sales-history",
        meta: {
          title: "Customer Sales History",
          authRequired: true
        },
        label: "t-customers-sales-history",
        component: () => import("../views/customers/CustomerSalesHistory"),
        permission: "customers_saleshistory_view"
      }
    ]
  },
  {
    session: "stock",
    icon: "ri-archive-line",
    name: "t-stock",
    childrens: [
      {
        path: "/stock/aging",
        name: "stock-aging",
        meta: {
          title: "Aging",
          authRequired: true
        },
        label: "t-aging",
        component: () => import("../views/stock/aging"),
        permission: "stock_aging_view"
      },
      {
        path: "/stock/demand-pattern",
        name: "stock-demand-pattern",
        meta: {
          title: "Demand Pattern",
          authRequired: true
        },
        label: "t-demand-pattern",
        component: () => import("../views/stock/DemandPattern"),
        permission: "stock_demandpattern_view"
      },
      {
        path: "/stock/inventory-history",
        name: "stock-inventory-history",
        meta: {
          title: "Inventory History",
          authRequired: true
        },
        label: "t-inventory-history",
        component: () => import("../views/stock/InventoryHistory"),
        permission: "stock_inventoryhistory_view"
      },
      {
        path: "/stock/products",
        name: "stock-products",
        meta: {
          title: "Products",
          authRequired: true
        },
        label: "t-products",
        component: () => import("../views/stock/Product"),
        permission: "stock_products_view"
      },
      {
        path: "/stock/inventory-situation",
        name: "stock-inventory-situation",
        meta: {
          title: "Inventory Situation",
          authRequired: true
        },
        label: "t-inventory-situation",
        component: () => import("../views/stock/InventorySituation"),
        permission: "stock_inventorysituation_view"
      },
      {
        path: "/stock/products-analisys",
        name: "stock-products-analisys",
        meta: {
          title: "Products Analisys",
          authRequired: true
        },
        label: "t-products-analisys",
        component: () => import("../views/stock/ProductsAnalysis"),
        permission: "stock_productsanalisys_view"
      },
      {
        path: "/stock/transfer-suggestions",
        name: "stock-transfer-suggestions",
        meta: {
          title: "Transfer Suggestions",
          authRequired: true
        },
        label: "t-transfer-suggestions",
        component: () => import("../views/stock/TransferSuggestion"),
        permission: "stock_transfersuggestions_view"

      },
    ]
  },
  {
    session: "input",
    icon: "ri-login-circle-line",
    name: "t-entries-purchases",
    childrens: [
      {
        path: "/input/purchase-forecasting-by-supplier",
        name: "input-purchase-forecasting-by-supplier",
        meta: {
          title: "Purchase Forecasting by Supplier",
          authRequired: true
        },
        label: "t-purchase-forecasting-by-supplier",
        component: () => import("../views/inputs/PurchaseForecastingBySupplier"),
        permission: "purchases_supplierforecasting_view"
      },
      {
        path: "/input/purchase-analysis",
        name: "input-purchase-analysis",
        meta: {
          title: "Purchase Analysis",
          authRequired: true
        },
        label: "t-purchase-forecasting",
        component: () => import("../views/inputs/PurchaseAnalysis"),
        permission: "purchases_analysis_view"
      },
      {
        path: "/input/purchase-history",
        name: "input-purchase-history",
        meta: {
          title: "Purchase History",
          authRequired: true
        },
        label: "t-purchase-history",
        component: () => import("../views/inputs/PurchaseHistory"),
        permission: "purchases_history_view"
      }
    ]
  },
  {
    session: "sales",
    icon: "ri-logout-circle-r-line",
    name: "t-exits-sales",
    childrens: [
      {
        path: "/sales/prices-comparison",
        name: "sales-prices-comparison",
        meta: {
          title: "Prices Comparison",
          authRequired: true
        },
        label: "t-prices-comparison",
        component: () => import("../views/sales/PriceComparison"),
        permission: "sales_pricescomparison_view"
      },
      {
        path: "/sales/forecasting",
        name: "sales-forecasting",
        meta: {
          title: "Forecasting",
          authRequired: true
        },
        label: "t-forecasting",
        component: () => import("../views/sales/forecasting"),
        permission: "sales_forecasting_view"
      },
      {
        path: "/sales/sales-history",
        name: "sales-sales-history",
        meta: {
          title: "Sales History",
          authRequired: true
        },
        label: "t-sales-history",
        component: () => import("../views/sales/SalesHistory"),
        permission: "sales_history_view"
      },
      {
        path: "/sales/price-sensitivity",
        name: "sales-price-sensitivity",
        meta: {
          title: "Price Sensitivity",
          authRequired: true
        },
        label: "t-price-sensitive",
        component: () => import("../views/sales/price-sensitive"),
        permission: "sales_pricesensitivity_view"
      },
      {
        path: "/sales/categories-cross-selling",
        name: "sales-categories-cross-selling",
        meta: {
          title: "Categories Cross Selling",
          authRequired: true
        },
        label: "t-categories-cross-selling",
        component: () => import("../views/sales/CategoriesCrossSelling"),
        permission: "sales_categoriescrossselling_view"
      },
      {
        path: "/sales/products-correlation",
        name: "sales-products-correlation",
        meta: {
          title: "Products Correlation",
          authRequired: true
        },
        label: "t-products-correlation",
        component: () => import("../views/sales/ProductCorrelation"),
        permission: "sales_productscorrelation_view"
      }
    ]
  },
  {
    session: "suppliers",
    icon: "ri-shake-hands-line",
    name: "t-suppliers",
    childrens: [
      {
        path: "/suppliers/products-suppliers",
        name: "suppliers-products-suppliers",
        meta: {
          title: "Products Suppliers",
          authRequired: true
        },
        label: "t-products-suppliers",
        component: () => import("../views/suppliers/ProductsSuppliers"),
        permission: "suppliers_productssuppliers_view"
      },
      {
        path: "/suppliers/suppliers",
        name: "suppliers-suppliers",
        meta: {
          title: "Suppliers",
          authRequired: true
        },
        label: "t-suppliers",
        component: () => import("../views/suppliers/Suppliers"),
        permission: "suppliers_suppliers_view"
      },
      {
        path: "/suppliers/products",
        name: "suppliers-products",
        meta: {
          title: "Suppliers Products",
          authRequired: true
        },
        label: "t-products",
        component: () => import("../views/suppliers/Products"),
        permission: "suppliers_products_view"
      }
    ]
  },
  {
    session: "business",
    icon: "ri-building-2-line",
    name: "t-business",
    childrens: [
      {
        path: "/business/branches",
        name: "business-branches",
        meta: {
          title: "Branches",
          authRequired: true
        },
        label: "t-branches",
        component: () => import("../views/business/Branches"),
        permission: "business_branches_view"
      },
    ]
  },
  {
    session: "settings",
    icon: "ri-settings-3-line",
    name: "t-settings",
    childrens: [
      {
        path: "/settings/wpp-config",
        name: "settings-wpp-config",
        meta: {
          title: "WhatsApp Config",
          authRequired: true
        },
        label: "t-wpp-config",
        component: () => import("../views/settings/wpp-config.vue"),
        permission: "settings_whatsapp_view"
      },
      {
        path: "/settings/purchase-config",
        name: "settings-purchase-config",
        meta: {
          title: "Purchase Config",
          authRequired: true
        },
        label: "t-purchase-config",
        component: () => import("../views/settings/PurchaseConfig.vue"),
        permission: "settings_purchase_view"
      },
      {
        path: "/settings/stock-config",
        name: "settings-stock-config",
        meta: {
          title: "Stock Config",
          authRequired: true
        },
        label: "t-stock-config",
        component: () => import("../views/settings/StockConfig.vue"),
        permission: "settings_stockconfig_view"
      }
    ]
  },
  {
    session: "admin",
    icon: "ri-briefcase-4-line",
    name: "t-admin",
    childrens: [
      {
        path: "/admin/roles",
        name: "admin-roles",
        meta: {
          title: "Roles",
          authRequired: true
        },
        label: "t-roles",
        component: () => import("../views/admin/roles"),
        permission: "admin_roles_view"
      },
      {
        path: "/admin/users",
        name: "admin-users",
        meta: {
          title: "Team",
          authRequired: true
        },
        label: "t-users",
        component: () => import("../views/admin/users"),
        permission: "admin_users_view"
      }
    ]
  },
  {
    session: "profile",
    icon: "ri-profile-line",
    name: "t-profile",
    childrens: [
      {
        path: "/profile",
        name: "profile",
        meta: {
          title: "Profile",
          public: true
        },
        label: "t-profile",
        component: () => import("../views/user/profile")
      }
    ]
  },
  {
    session: "apps",
    icon: "ri-apps-2-line",
    name: "t-apps",
    childrens: [
      {
        path: "/apps/chat",
        name: "chat",
        meta: {
          title: "Chat",
          public: true
        },
        label: "t-apps",
        component: () => import("../views/apps/StockAssistant")
      }
    ]
  }
]